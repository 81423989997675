import {
  Base,
  Address,
  Dek,
  Thicker,
  SectionHed,
  Positions,
  Org,
  Description,
  List,
  Highlights,
  Highlight,
  Time,
} from "./stories/Layout";

const Resume: React.FC = () => (
  <>
    <Base>
      <Address>
        <Thicker>Jeff Larson</Thicker>
        <br />
        <a href="mailto:thejefflarson@gmail.com">thejefflarson@gmail.com</a>
      </Address>
      <Dek>
        Director of Proactive Engineering at Yahoo, with expertise in leading
        technical engineering teams, cybersecurity behavior change, executive
        communication and red team engagements.
      </Dek>
      <SectionHed>Experience</SectionHed>
      <Positions>
        <Org>
          <Thicker>Yahoo</Thicker> Director, Proactive Engineering{" "}
          <Time>December 2020&thinsp;&ndash;&thinsp; Present</Time>
          <Description>
            <p>
              As the Director of Proactive Engineering, a pillar within Yahoo's
              security team, the Paranoids, I lead a 30 person team that has one
              mission: make the safe choice the easy choice through a
              combination of empathy and user centered design. We challenge
              dangerous assumptions, build technical assumptions and drive
              measurable behavior change to defend Yahoo against the most
              pressing attacks we face as a company.
            </p>
            <p>
              The team consists of Yahoo's red team, cybersecurity engineering
              team and its security awareness team.
            </p>
            <p>
              It works like this: the red team finds an attack path, usually
              utilizing tools created by our vulnerability research team. Then,
              our security awareness team catalyzes behavior change using
              behavioral science methods to block that kill chain. Finally,
              where possible, our engineering team makes those attacks
              impossible through targeted development of technical security
              solutions.
            </p>
            <Highlights>Highlights</Highlights>
            <List>
              <li>
                After log4j, drove the creation of a container scanning pipeline
                and kubernetes webhook that signs and scans tens of thousands of
                containers every single day to ensure that dangerous
                vulnerabilities don't end up in production. The system was
                adopted by thousands of Yahoo engineers without a major
                incident.
              </li>
              <li>
                Led our push to switch our SSO portal to phishing resistant
                webauthn, with over 90% of the company voluntarily adopting;
                similarly, for consumers, created a program to encourage 2SV
                adoption with 5 million users adopting the feature in 2024.
              </li>
              <li>Created the Paranoids government relations program.</li>
              <li>
                Created an executive protection program to ensure our executives
                are not personally vulnerable to targeted harrassment.
              </li>
            </List>
          </Description>
        </Org>
        <Org>
          <Thicker>Turntide Technologies</Thicker> Senior Software Engineer{" "}
          <Time>September 2019&thinsp;&ndash;&thinsp;Present</Time>
        </Org>
        <Description>
          <p>
            As a Senior Software Engineer at Turntide, I worked with my
            colleagues to reduce the environmental impact of air conditioning
            systems in an effort to do our bit to help slow climate change. It
            was a rewarding position at the crossroads of embedded devices and
            cloud computing. I built distributed systems using{" "}
            <Highlight>Kafka</Highlight>, <Highlight>Go</Highlight>, and{" "}
            <Highlight>GraphQL</Highlight>. I investigated equipment failures
            and performance issues with my knowledge of machine learning and
            statistics. And I developed customizable dashboards to help measure
            reliability and monitor running systems.
          </p>
          <Highlights>Highlights</Highlights>
          <List>
            <li>
              Extended the existing frontend dashboards to allow for custom
              extensions and visualizations using{" "}
              <Highlight>Typescript</Highlight>, <Highlight>React</Highlight>{" "}
              and <Highlight>Material UI</Highlight>.
            </li>
            <li>
              Refactored and improved an external api for customers so that they
              could gather alerts and metrics about their installed systems.
            </li>
            <li>
              Provisioned infrastructure using <Highlight>Pulumi</Highlight>,{" "}
              <Highlight>Kubernetes</Highlight>, <Highlight>Skaffold</Highlight>{" "}
              and <Highlight>Helm</Highlight>.
            </li>
            <li>
              Developed an internal dashboard to monitor reliability metrics to
              help the company improve its product.
            </li>
          </List>
        </Description>
        <Org>
          <Thicker>The Markup</Thicker> Managing Editor{" "}
          <Time>April 2018&thinsp;&ndash;&thinsp;April 2019</Time>
        </Org>
        <Description>
          <p>
            I cofounded The Markup, a nonprofit publication illuminating the
            societal implications of emerging technologies. With my colleagues,
            I created newsroom policies including a data style guide, privacy
            policy and The Markup's data ethics guide. I worked with outside
            firms to create our online identity and public messaging. I wrote
            the editorial vision, created an editorial pitching process and
            oversaw the editorial calendar. I managed a team of seven
            journalists and motivated them to do their best work. I also wrote a
            lot of code.
          </p>
          <Highlights>Highlights</Highlights>
          <List>
            <li>
              With my co-founders, successfully raised $23 Million from Craig
              Newmark Philanthropies, the Knight Foundation, the MacArthur
              foundation and others.
            </li>
            <li>
              Wrote a privacy-preserving analytics system using{" "}
              <Highlight>differential privacy</Highlight> in{" "}
              <Highlight>TypeScript</Highlight> on the frontend and{" "}
              <Highlight>Rust</Highlight> on the backend.
            </li>
            <li>
              Provisioned infrastructure on AWS using{" "}
              <Highlight>Terraform</Highlight> and{" "}
              <Highlight>Kubernetes</Highlight>.
            </li>
            <li>
              Created a hiring process that was the envy of the media industry
              and led our diversity efforts.
            </li>
          </List>
        </Description>
        <Org>
          <Thicker>ProPublica</Thicker> News Applications Developer{" "}
          <Time>August 2009&thinsp;&ndash;&thinsp;April 2018</Time>
        </Org>
        <Description>
          <p>
            For almost a decade, I was an award-winning developer and data
            journalist at ProPublica, a nonprofit news organization in New York
            City. I worked hard with my colleagues to create compelling
            visualizations and informative articles while meeting the deadlines
            of the news cycle. As a technical leader, I was an evangelist for
            our open source efforts, an expert in{" "}
            <Highlight>geographic information systems</Highlight> and mapping
            for the web, and a mentor for my colleagues. As a data scientist, I
            used <Highlight>machine learning</Highlight> and{" "}
            <Highlight>statistics</Highlight> to investigate political
            targeting, racially biased car insurance pricing, and discriminatory
            advertising on Facebook. As a reporter, I was a member of the team
            reporting on the Snowden leaks, a two-time Pulitzer Prize finalist,
            and winner of the Livingston award for promising young journalists.
          </p>

          <Highlights>Highlights</Highlights>
          <List>
            <li>
              Developed an open source server-side mapping framework using{" "}
              <Highlight>GDAL</Highlight> written in <Highlight>C</Highlight>,
              and a frontend framework for SVG mapping. Developed dozens of
              performant interactive <Highlight>GIS</Highlight> visualizations
              using <Highlight>WebGL</Highlight>, <Highlight>Leaflet</Highlight>
              , <Highlight>SVG</Highlight> and <Highlight>D3</Highlight> on
              deadline.
            </li>
            <li>
              Developed a browser extension to crowdsource and identify
              political ads from Facebook. Wrote a backend in{" "}
              <Highlight>Rust</Highlight> that collected millions of ads. Wrote
              a classifier with <Highlight>scikit-learn</Highlight> that
              correctly predicted if an ad was political more than 90% of the
              time. Wrote a frontend in <Highlight>React</Highlight> and{" "}
              <Highlight>Redux</Highlight> that displays more than 100,000
              political ads.
            </li>
            <li>
              Reported on the Snowden Leaks with journalists from the New York
              Times and the Guardian. Wrote a <Highlight>Java</Highlight>{" "}
              application using <Highlight>Apache Tika</Highlight>,{" "}
              <Highlight>Lucene</Highlight> and <Highlight>JavaFX</Highlight> to
              search through millions of documents.
            </li>
            <li>
              Developed ProPublica’s Prescriber Checkup tool that tracks
              prescribing patterns for almost 1 million doctors in Medicare.
              Wrote a system to detect doctors with abnormal prescribing
              patterns.
            </li>
            <li>
              Analyzed a risk algorithm used by criminal courts around the
              country using <Highlight>R</Highlight> and found that the
              algorithm was biased against African Americans for a series called
              Machine Bias.
            </li>
            <li>
              Analyzed millions of car insurance quotes using{" "}
              <Highlight>general additive models</Highlight> and found troubling
              evidence unfair pricing for minority communities.
            </li>
            <li>
              Created and continue to maintain open source projects in use at
              newsrooms around the world. Developed dozens of production-quality
              web applications with <Highlight>Ruby</Highlight>,{" "}
              <Highlight>Node</Highlight>, <Highlight>PHP</Highlight>,{" "}
              <Highlight>Python</Highlight> and <Highlight>Rust</Highlight>.
            </li>
          </List>
        </Description>
        <Org>
          <Thicker>Columbia University</Thicker> Assistant Professor
          <Time>September 2016&thinsp;&ndash;&thinsp;December 2016</Time>
        </Org>
        <Description>
          <p>
            I taught a class on analyzing geospatial data to nine journalism
            students as part of the Journalism school's Lede Program. We covered
            the basics like coordinate systems, <Highlight>QGIS</Highlight> and
            the Open Geospatial Consortium's simple feature geometry object
            model. We advanced to web mapping with{" "}
            <Highlight>GeoJSON</Highlight>, <Highlight>SVG</Highlight>,{" "}
            <Highlight>D3</Highlight> and <Highlight>Leaflet</Highlight>. Then,
            we covered advanced topics like processing shapefiles in the{" "}
            <Highlight>python</Highlight> library <Highlight>fiona</Highlight>{" "}
            and analyzing and color correcting raster images with{" "}
            <Highlight>rasterio</Highlight> and <Highlight>numpy</Highlight>.
            Finally, we built a{" "}
            <Highlight>logistic regression classifier</Highlight> using{" "}
            <Highlight>statsmodels</Highlight> to classify public parks. It was
            a rewarding and fulfilling experience.
          </p>
        </Description>
        <Org>
          <Thicker>The Nation</Thicker> Online Director
          <Time>September 2007&thinsp;&ndash;&thinsp;August 2009</Time>
        </Org>
        <Org>
          <Thicker>W.W. Norton</Thicker> Editorial Assistant
          <Time>September 2007&thinsp;&ndash;&thinsp;August 2009</Time>
        </Org>
        <Org>
          <Thicker> Group 301 Media</Thicker> Assistant Film Editor{" "}
          <Time>June 2005&thinsp;&ndash;&thinsp;June 2006</Time>
        </Org>
      </Positions>
      <SectionHed>Education</SectionHed>
      <Positions>
        <Org>
          <Thicker>University of Santa Cruz</Thicker> Bachelor of Arts, English
          Language and Literature<Time>2002&thinsp;&ndash;&thinsp;2005</Time>
        </Org>
        <Org>
          <Thicker>University of Santa Barbara</Thicker> Computer Science Major
          <Time>2000&thinsp;&ndash;&thinsp;2002</Time>
        </Org>
      </Positions>
    </Base>
  </>
);

export default Resume;
